<template>
    <div class="user">
        <fromSearch :object_='object_' @searchFun='getList' />
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"  @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="viewOpen(0)">{{$t('currencySetNft.text2')}} </el-button>
            </template>  
            <template #appName='scope'>
                {{getAppName(scope.row.appId)}}
            </template>
            <template #chain_protocol='scope'>
                {{scope.row.chain}}/{{scope.row.protocol}}
            </template> 
            <template #doData='scope'>
                <el-button text type="primary" size="small"   v-if='scope.row.status  === 2' 
                    @click="audit(1,$t('withdrawNft.name6'),scope.row)">{{$t('withdrawNft.name6')}}</el-button>
                <el-button text type="primary" size="small"   v-if='scope.row.status === 0'   
                    @click="audit(3,$t('withdrawNft.name7'),scope.row)">{{$t('withdrawNft.name7')}}</el-button>
                <el-button text type="primary" size="small"   v-if='scope.row.status === 0'   
                    @click="audit(2,$t('withdrawNft.name8'),scope.row)">{{$t('withdrawNft.name8')}}</el-button>
            </template>   
            <template #caozuo='scope'>
                <!-- <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"    @click="viewOpen('update',scope.row)">{{$t('button.update')}} </el-button> -->
                <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"    @click="delFun(scope.row.applyId)">{{$t('button.del')}} </el-button>
            </template>
            <template #applyTime="scope">
                {{turnTimeFun(scope.row.applyTime)}}
            </template>
            <template #auditTime="scope">
                {{turnTimeFun(scope.row.auditTime)}}
            </template>
        </avue-crud>

        <el-dialog v-model="visibleBool" width="600" :title="textArray[operationStyle]" append-to-body >
            <addView ref='addView_' @successFun='successFun' v-if='operationStyle === 0'/>
        </el-dialog>

        <el-dialog v-model="auditParm.innerVisible1" width="600" :title="auditParm.titleText">
            <div style="margin-bottom: 10px">{{strArray[auditParm.style]}}</div>
            <el-input type="textarea" :placeholder="$t('inputTit.shuru')" v-model='auditParm.reasons' v-if='auditParm.style > 1'></el-input>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="auditParm.innerVisible1 = false">{{$t('button.close')}}</el-button>
                    <el-button type="primary" @click="enterFun">{{$t('button.enter')}}</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog v-model="auditParm.innerVisible" width="600" :title="$t('withdrawNft.name6')" append-to-body >
            <div>{{strArray[4]}}{{auditParm.code_ }}</div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="auditParm.innerVisible = false;auditParm.innerVisible1 = false">{{$t('button.close')}}</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,getCurrentInstance } from 'vue'
    import { tableOption1 } from "@/const/crud/nft/nftWithdrawUser";
    import apiList from '@/const/apiList'
    import addView from './add'
    // import copyView from './copy.vue'
    import { ElNotification,ElMessageBox } from 'element-plus'
    import { userStore } from '@/store/user'
    import { object } from '@/const/from/nft/nftWithdrawUser'
    import fromSearch from '@/components/fromSearch.vue'
    const object_ = ref(object);
    const { $t } = getCurrentInstance().proxy;
    import { randomCoding } from '@/utils/util.js'
    import mixins from '@/views/mixins/page'
import { turnTimeFun } from '@/utils/util.js'

    let pageObj = mixins(['pageByMerNft'],{applyUserType: 2,protocolType:2})
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj
    
    const visibleBool = ref(false)
    const textArray = [$t('currencySetNft.text2'),$t('withdrawNft.text2')]
    const operationStyle = ref(0)
    // const copyShow_ = ref(null)
    const addView_ = ref(null)
    const tableOption = ref({});
    const roleManager_btn_edit = ref(true)
    const roleManager_btn_add = ref(true)
    const serchValue = reactive({
        tenantId: '',
        appId: '',
        status: '',
        createTimes: [],
        chainArray:[],
        coin:'',
        walletName: ''
    })
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    serchValue.tenantId = userStore().getUserInfo.tenantId
    const tenantArray = ref([])
    const appArray = ref([])
    const chainList = ref([])

    const strArray = ['',$t('withdraw.paragraph1'),$t('withdraw.paragraph2'),$t('withdraw.paragraph3'),$t('withdraw.paragraph4')]
    // const textArray = ['',]
    const auditParm = reactive({
        style: '',
        titleText: '',
        innerVisible1: false,
        innerVisible: false,
        obj: {},
        reasons: '',
        code_:''
    })

    apiList.getTenantList((data)=>{ tenantArray.value = data })
    apiList.getAppList((data)=>{appArray.value = data;
    tableOption.value = tableOption1(data)})
    apiList.getChainList((res)=>{chainList.value = res})

    const getAppName = (e)=>{
        if(e){
            for(let item of appArray.value){
                if(e == item.appId){
                    return item.appName
                } 
            }
        }
    }
    const audit = (e,str,obj_)=>{
        auditParm.style = e
        auditParm.titleText = str
        auditParm.innerVisible1 = true
        auditParm.reasons = ''
        if(obj_){
            auditParm.obj = obj_
        }
    }
    const enterFun = ()=>{
        if(auditParm.style === 1){//重新申请
            let obj = JSON.parse(JSON.stringify(auditParm.obj))
            obj.transactionId = randomCoding(32)
            delete obj.applyId
            Api_.addCustomerWithdrawal(obj).then(res=>{
                if(res.code === 0){
                    auditParm.innerVisible = true
                    auditParm.code_ = res.data.applyId
                    getList(1)
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }else{
            let obj_ = {
                auditReason: auditParm.reasons,
                applyId: auditParm.obj.applyId,
            }
            if(auditParm.style === 2){//拒绝
                if(!auditParm.reasons){
                    ElNotification({
                        message: $t('nfts.paragraph1'),
                        type: 'error',
                    })
                }else{
                    obj_.status = 2
                    Api_.updateCustomerWithdrawal(obj_).then(res=>{
                        if(res.code === 0){
                            ElNotification({
                                message: $t('alert.succBind'),
                                type: 'success',
                            })
                            getList(1)
                            auditParm.innerVisible1 = false
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }else{
                obj_.status = 1
                Api_.updateCustomerWithdrawal(obj_).then(res=>{
                    if(res.code === 0){
                        ElNotification({
                            message: $t('alert.succBind'),
                            type: 'success',
                        })
                        getList(1)
                        auditParm.innerVisible1 = false
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
        }
    }
    const successFun = () => {
        visibleBool.value = false
        page.currentPage = 1;
        getList(1)
    }
    const viewOpen = (num)=>{
        visibleBool.value = true
        operationStyle.value = num
    }
    const delFun = (roleId)=>{
        ElMessageBox.confirm($t('alert.selEnter'), $t('tyle_.tits'), {
            confirmButtonText: $t('button.enter'),
            cancelButtonText: $t('button.close'),
            type: 'warning',
        }).then(()=>{
            Api_.delAssetswalletinfoStatus(roleId).then(res=>{
                if(res.code ===0 ){
                    getList(1)
                    ElNotification({
                        message: $t('alert.succDel'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        })
    }
    getList(1)
</script>

